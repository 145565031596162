import {defer} from '@shopify/remix-oxygen';
//import {Suspense} from 'react';
//import {Await, useLoaderData} from '@remix-run/react';
//import {AnalyticsPageType} from '@shopify/hydrogen';

import { Link } from '@remix-run/react';

//import {ProductSwimlane,CustomProductsGrid} from '~/components';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
//import {getHeroPlaceholder} from '~/lib/placeholders';
import {seoPayload} from '~/lib/seo.server';
import {routeHeaders} from '~/data/cache';

import bulkOrder from 'public/bulk.webp'
import cutter from 'public/cutter.avif'
import marketplace from 'public/marketplace.webp'
import { AddToCartButton } from '~/components';
import { Text } from '~/components';

export const headers = routeHeaders;

export async function loader({params, context}) {
  const {language, country} = context.storefront.i18n;
  const productType = context.env.PUBLIC_PRODUCT_TYPE || 'Filament';
  if (
    params.locale &&
    params.locale.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the locale URL param is defined, yet we still are on `EN-US`
    // the the locale param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const {shop, hero} = await context.storefront.query(HOMEPAGE_SEO_QUERY, {
    variables: {handle: 'freestyle'},
  });

  const seo = seoPayload.home();

let featuredProducts = await context.storefront.query(
  HOMEPAGE_FEATURED_PRODUCTS_QUERY,
  {
    variables: {
      country,
      language,
    },
  },
);

const filteredNodes = featuredProducts.products.nodes.filter(
  (product) => product.productType !== productType
);

 featuredProducts = {
  products: {
    nodes: filteredNodes
  }
};

  return defer({
    shop,
    featuredProducts: featuredProducts,
    seo,
  });
}

export default function Homepage() {
    const bulk = `We’ve made custom cookie cutters for Duolingo, Netflix, Nickelodeon, universities, theme retail shops, parties, and graduations. We can help wow your customers, employees, and friends, too. And we’ll make your cutters fast, when you need them.`
    
    const market = `Shop over 1000 unique cookie cutters designed by independent designers in the Cookiecad Marketplace.
    You'll find every shape you need: trucks, plaques, graduation, animals, baby, wedding holiday cutters and more.`
    
    const design = `Instantly turn an image into a Cookie Cutter. Upload an image and Cookiecad will show you a 3D preview of your cutter.
    Purchase your custom cutter and get it delivered to your home or business today.`
    


    return (
        <article className="flex flex-col gap-10 my-5 p-4 max-w-6xl mx-[auto]">
            <HomepageSection 
                title='Bulk Cookie Cutter Orders' 
                image={bulkOrder}
                buttonTitle='Learn More' 
                text={bulk}
                linkAddress='/bulk-cutters'
                className='md:flex-row' 
                divClass='lg:pl-[15%] lg:pr-[5%]'/>
            <HomepageSection 
                title='Cookie Cutter Marketplace' 
                image={marketplace}
                buttonTitle='Shop Now' 
                text={market}
                linkAddress='/featured'
                className='md:flex-row-reverse' 
                divClass='lg:pr-[15%] lg:pl-[5%]'/>
            <HomepageSection 
                title='Design your own Cookie Cutter' 
                image={cutter}
                buttonTitle='Cutter Maker' 
                text={design}
                linkAddress='https://custom.cookieswag.com'
                className='md:flex-row' 
                divClass='lg:pl-[15%] lg:pr-[5%]'/>
        </article>
    )
}


const HomepageSection = ({ title, image, buttonTitle, className, text, linkAddress, divClass }) => {
    return (
    <section className={`${className} flex flex-col gap-4 items-center py-12 lg:px-5`}>
        <img src={image} className='object-scale-down min-w-[323px] p-[15px] flex-1' />
        <div className='flex flex-1 flex-col gap-2'>
            <div className={`${divClass} flex flex-col p-[15px] justify-center w-full`}>
                <h2 className='text-[22px] font-bold pb-8 self-start'>{title}</h2>
                <p className='text-base whitespace-pre-line'>{text}</p>
                <Link to={linkAddress} className='landingButtonPrimary'>{buttonTitle}</Link>
            </div>
        </div>
    </section>
    )
}


const COLLECTION_CONTENT_FRAGMENT = `#graphql
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
  ${MEDIA_FRAGMENT}
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  query seoCollectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
  ${COLLECTION_CONTENT_FRAGMENT}
`;

const COLLECTION_HERO_QUERY = `#graphql
  query heroCollectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
  ${COLLECTION_CONTENT_FRAGMENT}
`;

// @see: https://shopify.dev/api/storefront/2023-07/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 50) {
      nodes {
        ...ProductCard
      }
    }
  }
  ${PRODUCT_CARD_FRAGMENT}
`;

// @see: https://shopify.dev/api/storefront/2023-07/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
